/* eslint-disable import/prefer-default-export */

const DEFAULT_TEMPLATE = {
  id: null,
  name: null,
  subject: null,
  htmlTemplate: null,
  textTemplate: null,
};

export { DEFAULT_TEMPLATE };
