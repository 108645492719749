<template>
  <TemplatesTable
    :templates="templates"
    :loading="loading"
  >
    <template #topActions>
      <VBtn
        color="primary"
        :to="{ name : Names.R_NOTIFICATION_TEMPLATES_CREATE }"
      >
        <VIcon left>
          fal fa-plus
        </VIcon>

        Новый шаблон
      </VBtn>

      <VDialog
        v-model="confirmDialog"
        max-width="500px"
        @click:outside="handleCancel"
      >
        <VCard>
          <VCardTitle class="headline">
            Удалить шаблон
          </VCardTitle>

          <VCardText>
            Шаблон <span class="red--text">{{ template.name }}</span> будет удален! <br>
            Действительно хотите удалить шаблон?
          </VCardText>

          <VCardActions>
            <VSpacer />

            <VBtn
              text
              @click="handleCancel"
            >
              Нет
            </VBtn>

            <VBtn
              color="red darken-1"
              text
              @click="handleDelete"
            >
              Да
            </VBtn>
          </VCardActions>
        </VCard>
      </VDialog>
    </template>

    <template #rowActions="{ item }">
      <VTooltip left>
        <template #activator="{ on, attrs }">
          <VBtn
            icon
            small
            color="blue"
            class="mx-1"
            :to="{ name : Names.R_NOTIFICATION_TEMPLATES_VIEW, params : { templateName : item.name } }"
            v-bind="attrs"
            v-on="on"
          >
            <VIcon small>
              fal fa-eye
            </VIcon>
          </VBtn>
        </template>

        <span>Просмотр</span>
      </VTooltip>

      <VTooltip left>
        <template #activator="{ on, attrs }">
          <VBtn
            icon
            small
            color="orange"
            class="mx-1"
            :to="{ name : Names.R_NOTIFICATION_TEMPLATES_EDIT, params : { templateName : item.name } }"
            v-bind="attrs"
            v-on="on"
          >
            <VIcon small>
              fal fa-edit
            </VIcon>
          </VBtn>
        </template>

        <span>Редактирование</span>
      </VTooltip>

      <VTooltip left>
        <template #activator="{ on, attrs }">
          <VBtn
            icon
            small
            color="red"
            class="mx-1"
            v-bind="attrs"
            v-on="on"
            @click.stop="deleteRoute(item)"
          >
            <VIcon small>
              fal fa-trash
            </VIcon>
          </VBtn>
        </template>

        <span>Удаление</span>
      </VTooltip>
    </template>
  </TemplatesTable>
</template>

<script>
import { DEFAULT_TEMPLATE } from './common';
import TemplatesTable from './TemplatesTable.vue';

export default {
  name: 'TemplatesTableContainer',

  components: {
    TemplatesTable,
  },

  inject: ['Names'],

  data() {
    return {
      confirmDialog: false,
      templates: [],
      template: { ...DEFAULT_TEMPLATE },
      loading: false,
    };
  },

  async created() {
    await this.fetch();
  },

  methods: {
    fetch() {
      this.loading = true;

      this.$di.api.Notification
        .TemplatesIndex()
        .then(({ templates }) => {
          this.templates = templates;
        })
        .catch(this.$di.notify.errorHandler)
        .finally(() => {
          this.loading = false;
        });
    },

    deleteRoute(item) {
      this.confirmDialog = true;
      this.template = { ...item };
    },

    handleDelete() {
      this.loading = true;

      const { name } = this.template;

      this.$di.api.Notification
        .TemplatesDelete({ name })
        .then((response) => {
          this.routes = response;
          this.resetRouteEdit();
        })
        .catch(this.$di.notify.errorHandler)
        .finally(() => {
          this.loading = false;
          this.fetch();
        });
    },

    handleCancel() {
      this.resetRouteEdit();
    },

    resetRouteEdit() {
      this.confirmDialog = false;
      this.formDialog = false;
      this.template = { ...DEFAULT_TEMPLATE };
    },
  },
};
</script>
