<template>
  <TTView>
    <VRow>
      <VCol>
        <TemplatesTableContainer />
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
import TemplatesTableContainer from '../../components/notification/TemplatesTableContainer.vue';

export default {
  name: 'TemplatesList',

  components: {
    TemplatesTableContainer,
  },

  inject: ['Names'],
};
</script>
