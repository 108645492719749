<template>
  <VDataTable
    class="elevation-1"
    :headers="headers"
    :items="templates"
    :loading="loading"
  >
    <template #top>
      <VToolbar flat>
        <VToolbarTitle>Шаблоны писем</VToolbarTitle>

        <VSpacer />

        <slot name="topActions" />
      </VToolbar>
    </template>

    <template #item.actions="{ item }">
      <slot
        name="rowActions"
        :item="item"
      />
    </template>
  </VDataTable>
</template>

<script>
export default {
  name: 'TemplatesTable',

  props: {
    loading: {
      type: Boolean,
      default: false,
    },

    templates: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      headers: [
        {
          text: 'Название',
          align: 'left',
          value: 'name',
        },
        {
          text: 'Тема',
          align: 'left',
          value: 'subject',
        },
        {
          text: '',
          align: 'right',
          sortable: false,
          value: 'actions',
        },
      ],
    };
  },
};
</script>
